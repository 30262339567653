/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reports': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<polygon points="117 107 117 0 96 0 96 107 74.5 107 74.5 27 53.5 27 53.5 107 32 107 32 49 11 49 11 107 0 107 0 128 128 128 128 107 117 107"/>'
  }
})

