/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'products': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M127.55,23.33Q112,15.1,96.5,6.86C92.13,4.55,87.74,2.29,83.35,0A20,20,0,0,1,64,15.18,20,20,0,0,1,44.64,0Q28.5,8.52,12.35,17C8.26,19.16,4.18,21.34,0,23.56c3.54,8.39,7,16.67,10.51,25,.31.73.77.61,1.29.54l14.63-2V128h75.18V47.11c5,.67,9.87,1.32,14.74,2,.68.1,1-.15,1.26-.78,2.63-6.32,5.3-12.63,8-18.94.81-1.93,1.61-3.87,2.43-5.82Z"/>'
  }
})





