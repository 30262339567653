/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'site': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M122.72,0H5.28A5.3,5.3,0,0,0,0,5.28V122.72A5.3,5.3,0,0,0,5.28,128H122.72a5.3,5.3,0,0,0,5.28-5.28V5.28A5.3,5.3,0,0,0,122.72,0ZM57,101.82c0,3.19-1.2,4.38-4.39,4.38q-20.22,0-40.45,0c-3.17,0-4.39-1.21-4.39-4.39q0-20.22,0-40.45c0-3.2,1.19-4.38,4.38-4.39q20.24,0,40.46,0C55.74,57,57,58.2,57,61.37c0,6.74,0,13.48,0,20.23S57,95.08,57,101.82ZM12.77,49.93c-4.07,0-5-1-5-5.06,0-6.23,0-12.46,0-18.69,0-3.19,1.19-4.38,4.39-4.38q51.89,0,103.76,0c3.2,0,4.38,1.18,4.39,4.38q0,9.57,0,19.13c0,3.47-1.14,4.61-4.61,4.61H12.77ZM67.19,71h49.88c3,0,3.22.29,3.21,3.79,0,3-.3,3.25-3.31,3.25q-12.4,0-24.83,0H67.31c-3.07,0-3.3-.25-3.31-3.45S64.22,71,67.19,71ZM64,60.54c0-3.3.22-3.57,3.2-3.57h49.87c3,0,3.21.27,3.21,3.56S120,64,117,64H67.32C64.23,64,64,63.73,64,60.54Zm3.2,24.57q25,0,50.1,0c2.74,0,3,.33,3,3.56s-.26,3.46-3.1,3.46H91.92q-12.3,0-24.61,0c-3.07,0-3.32-.29-3.32-3.46S64.22,85.11,67.2,85.11Zm-.1,14.07q12.63,0,25.26,0,12.42,0,24.83,0c2.78,0,3.08.35,3.09,3.24,0,3.44-.22,3.78-3,3.78q-25.16,0-50.31,0c-2.73,0-3-.34-3-3.57S64.25,99.19,67.1,99.18Z"/>'
  }
})




