/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'media': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M123.16,12.86q-31,0-62,0a4.79,4.79,0,0,1-3.86-1.62c-3-3.16-6.19-6.16-9.23-9.31A6,6,0,0,0,43.4,0C30.51.07,17.63,0,4.74,0,.88,0,0,.9,0,4.7V123.11c0,4.1.8,4.89,5,4.89h118c4.29,0,5-.75,5-5V17.66C128,13.69,127.14,12.86,123.16,12.86ZM38.5,38.42A12.8,12.8,0,1,1,25.65,51,12.8,12.8,0,0,1,38.5,38.42Zm65.21,66.85a3.28,3.28,0,0,1-1.22.31l0,0H25.39a3.07,3.07,0,0,1-1.95-.93,3,3,0,0,1-.34-4.21,7.51,7.51,0,0,1,.88-1q8.86-8.88,17.75-17.74c2.2-2.19,3.21-2.27,5.8-.55L63,91.48C66.51,85.55,70,79.79,73.43,74c2.21-3.68,4.39-7.38,6.63-11,1.87-3,4.67-3,6.28.25q9.31,18.51,18.55,37.09C106,102.57,105.5,104.46,103.71,105.27Z"/>'
  }
})


