/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bullet': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<defs><style>.cls-1{fill:none;}</style></defs><circle class="cls-1" cx="64" cy="64" r="64"/><circle cx="64" cy="69.25" r="39.75"/>'
  }
})
