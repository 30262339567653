/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'orders': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M115.87,0H12.13A12.17,12.17,0,0,0,0,12.13V115.87A12.17,12.17,0,0,0,12.13,128H115.87A12.17,12.17,0,0,0,128,115.87V12.13A12.17,12.17,0,0,0,115.87,0Zm3,36.67L47.42,108.15a4,4,0,0,1-3.17,1.14,4,4,0,0,1-3.18-1.14l-32-32a4,4,0,0,1,0-5.66L20.25,59.35a4,4,0,0,1,5.66,0L44.25,77.69l57.84-57.84a4,4,0,0,1,5.66,0L118.91,31A4,4,0,0,1,118.91,36.67Z"/>'
  }
})



