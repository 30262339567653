import request from '@/utils/request'

export const getUserInfo = (token: any) => {
  return (new Promise<any>((resolve, reject) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const parsedJSON = JSON.parse(jsonPayload)
    // const ret = JSON.parse(`{"code":20000,"data":{"user":{"id":0,"username":"admin","password":"any","name":"Super Admin","avatar":"https://i.pravatar.cc/150?img=8","introduction":"I am a super administrator","email":"admin@test.com","phone":"1234567890","roles":["admin"]}}}`);
    resolve(parsedJSON);
  }))
}

export const login = (data: any) =>
{
  return (new Promise<any>((resolve, reject) => {
    debugger
    resolve(JSON.parse(`{"code":20000,"data":{"accessToken":"admin-token"}}`))
  }))
}

export const logout = () => {
  return (new Promise<any>((resolve, reject) => {
    resolve(JSON.parse(`{"code":20000}`))
  }))
}
