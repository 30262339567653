/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'empty-bullet': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<defs><style>.cls-1{fill:none;}</style></defs><circle class="cls-1" cx="64" cy="64" r="64"/><path d="M64,41.5A27.75,27.75,0,1,1,36.25,69.25,27.78,27.78,0,0,1,64,41.5m0-12a39.75,39.75,0,1,0,39.75,39.75A39.79,39.79,0,0,0,64,29.5Z"/>'
  }
})


